$font-stack: "Helvetica Neue", Helvetica, Arial, sans-serif;
$max-width: 100vw;
$footer-height: 35px;
$z-index: (
  blur: 199,
  spinner: 200,
);

$color: (
  primary-color: #1aab48,
  secondary-color: #a14531,
  background-color: #bbb,
  selected-background-color: #44693d,
  button-color: white,
  button-hover-color:  #000000,
  button-hover-background-color: white,
  banner-color: #fefefe,
  dropdown-text-color: #333,
  mobile-text-color: #fefefe,
  caret-dropdown: #2a6496,
);

.shared-layout-body {
  font-family: $font-stack;
  max-width: $max-width;
  margin: auto;
  overflow-x: visible;
}


.blur{
  left:0;right:0;top:0;bottom:0;
  height: 100vh;
  width: 100%;
  z-index: map-get($z-index, blur);
  backdrop-filter: blur(8px);
  position:fixed;
  padding:0;
  margin:0;
}

@function side_space() {
  $tmp: max(100vw, $max-width);
  @return calc(
    (#{$tmp} - #{$max-width}) / 2
  ); // there is a max width on the body. This function will get the screen space to the left of that space.
}

%row {
  display: flex;
}
%column {
  @extend %row;
  flex-direction: column;
}
%row-wrap {
  @extend %row;
  flex-wrap: wrap;
}
%column-wrap {
  @extend %column;
  flex-wrap: wrap;
}
//---------------

@mixin row-mixin {
  display: flex;
}

@mixin column-mixin {
  @include row-mixin();
  flex-direction: column;
}

@mixin row-wrap-mixin {
  @include row-mixin();
  flex-wrap: wrap;
}

@mixin column-wrap {
  @include column-mixin();
  flex-wrap: wrap;
}

%button {
  height: 40px;
  line-height: 1.5;
  border-radius: 0%;
  color: map-get($color, button-color);
  background-color: map-get($color, primary-color);
  border-color: transparent;
  &:hover {
    color: map-get($color, button-hover-color);
    cursor: pointer;
    background-color: map-get($color, button-hover-background-color);
  }
}

%full-width {
  position: relative;
}

@mixin banner($banner-color: map-get($color, primary-color)) {
  @extend %full-width;
  @include row-mixin();
  background-color: $banner-color;
  color: map-get($color, banner-color);
  box-sizing: border-box;
  align-items: center;
  > * {
    flex-grow: 1;
    height: 100%;
  }
}

%login-banner {
  @include banner();
  height: 40px;
}

@mixin normal-background-mixin() {
  background-color: map-get($color, background-color) !important;
}
%normal-background {
  background-color: map-get($color, background-color);
}

%selected-background {
  background-color: map-get($color, selected-background-color);
}

@mixin side-padding($left, $right) {
  padding-left: $left;
  padding-right: $right;
}

html {
  overflow-x: hidden !important;
}

body{
  padding-bottom: $footer-height;
}

// This class has a weird interaction so we have to put it at the base level
// to stop it from being scoped
.mat-expansion-panel-body
{
  padding: 0 24px !important; // This loses the priority war to an inline angular style so it has to be important
}
